<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left pa-0">
                        <v-btn x-small color="success" @click="add">Adicionar turno<v-icon x-small right> add_circle </v-icon></v-btn>
                    </th>
                    <th class="text-left">Habilitado</th>
                    <th class="text-left">Inicio</th>
                    <th class="text-left">Fim</th>
                    <th class="text-left">Intervalo Refeição</th>
                    <th class="text-left">Total</th>
                    <th class="text-left"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(i, index) in schedule" :key="i">
                    <td>{{ index + 1 }}º Turno</td>
                    <td>
                        <v-switch v-model="i.enable" dense />
                    </td>
                    <td>
                        <v-text-field inputmode="numeric" v-model="i.start" :rules="[rules.required, rules.time]" v-mask="'##:##'" />
                    </td>
                    <td>
                        <v-text-field inputmode="numeric" v-model="i.stop" :rules="[rules.required, rules.time, minOutput(i.start)]" v-mask="'##:##'" />
                    </td>
                    <td>
                        <v-select v-model="i.interval" :items="intervalOptions" item-value="value" item-text="text" style="width: 150px" outlined :rules="[rules.range(0, 120)]" />
                    </td>
                    <td>
                        {{ getWorkerTime(i.start, i.stop, i.interval) }}
                    </td>
                    <td>
                        <v-btn small color="error" @click="remove(index)"><v-icon small> delete </v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        schedule: {
            type: Array,
        },
    },

    watch: {
        schedule: {
            immediate: true,
            handler(newVal) {
                this.$nextTick(() => {
                    if (newVal == null) {
                        this.numberLines = 1;
                    } else {
                        this.numberLines = this.schedule ? this.schedule.length : 1;
                    }
                });
            },
        },
    },

    data() {
        return {
            rules,
            numberLines: 1,
            intervalOptions: [
                { text: "-", value: 0 },
                { text: "30 Minutos", value: 30 },
                { text: "1 Hora", value: 60 },
            ],
            minOutput: (start) => (value) => this.checkMinOutput(value, start) || this.$t("Horario de fim deve ser maior que de inicio."),
        };
    },

    methods: {
        checkMinOutput(value, start) {
            try {
                return this.$moment(value, "HH:mm") > this.$moment(start, "HH:mm");
            } catch (e) {
                return false;
            }
        },
        add() {
            if (this.schedule && this.numberLines <= 5) {
                this.numberLines++;
                let actual = this.schedule ? this.schedule.length : 0;
                for (let index = actual; index < this.numberLines; index++) {
                    this.schedule.push({ enable: true, interval: 60 });
                }
            }
        },
        remove(index) {
            if (this.schedule.length >= index) {
                this.schedule.splice(index, 1);
                this.numberLines--;
            }
        },
        getWorkerTime(start, stop, interval) {
            if (start && stop && start.length == 5 && stop.length == 5) {
                var startTime = this.$moment(start, "HH:mm");
                var endTime = this.$moment(stop, "HH:mm");

                if (interval) {
                    endTime.subtract({ minutes: interval });
                }

                let diff = endTime.diff(startTime, "minutes");

                var hour = parseInt(diff / 60);
                var minutes = parseInt(diff % 60);
                return `${hour}h ${minutes}m`;
            }
            return "-";
        },
    },
};
</script>
